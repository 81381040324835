<template>
  <div class="row">
    <div class="bg-white p-4 rounded shadow mt-4 col-11 col-sm-8 col-md-6 ml-auto mr-auto">

      <b-overlay :show="loading" variant="light" spinner-type="grow">
        <form @submit.prevent="login" autocomplete="off">

          <h1 class="h3 mb-3 font-weight-normal">Please sign in</h1>

          <input type="email"
                 name="email"
                 class="form-control mb-1"
                 placeholder="Email address"
                 data-lpignore="true"
                 required
                 v-focus:select
                 v-model="email"
          />
          <input type="password"
                 name="password"
                 class="form-control"
                 placeholder="Password"
                 data-lpignore="true"
                 required
                 v-model="password"
          />

          <button class="btn btn-lg btn-primary btn-block mt-4" type="submit">Sign in</button>

        </form>
      </b-overlay>

    </div>
  </div>
</template>

<script>

export default {

    name      : 'Login',
    mixins    : [],
    components: {},

    props: {},

    data () {
        return {

            loading : true,
            email   : '',
            password: ''

        };
    },

    methods: {

        async login () {
            try {

                this.loading = true;

                let response = await this.$store.dispatch ( 'user/login', {
                    email   : this.email,
                    password: this.password
                } );

                this.loading = false;
                this.$utils.logger ( 'info', `[AUTH] User ${ response.data.user.name } logged in.` );

                // Goto dashboard
                await this.$router.push ( { name: 'dashboard' } );

            } catch ( e ) {
                this.$utils.logger ( 'error', '[AUTH] Login error', e );
                await this.$error (
                    {
                        title: 'Login failed',
                        html : e.message
                    }
                );
                this.loading = false;
            }
        }

    },

    mounted () {
        this.loading = false;
    }

};

</script>

<style scoped lang="scss">

</style>
